import styled from "styled-components";

const CollectionSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 78%;
    padding-bottom: 4rem;
    
`

export{CollectionSliderContainer}